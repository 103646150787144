import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { NgSelectModule } from "@ng-select/ng-select";
import { ClipboardModule } from "ngx-clipboard";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { InputSwitchModule } from "primeng/inputswitch";
import { SelectButtonModule } from "primeng/selectbutton";
import { TableModule } from "primeng/table";

import { ActionStatusPipe } from "../left-bar/actions/shared/action-status.pipe";
import { VoyageStatusPipe } from "../left-bar/voyages/shared/voyage-status.pipe";
import { FixtureAutosuggestComponent } from "./components/autosuggest/fixture/fixture-autosuggest.component";
import { UserAutosuggestComponent } from "./components/autosuggest/user/user-autosuggest.component";
import { VesselAutosuggestComponent } from "./components/autosuggest/vessel/vessel-autosuggest.component";
import { BusyBoxComponent } from "./components/busy-box/busy-box.component";
import { OpsButtonModule } from "./components/buttons";
import { DateInputComponent, DatePickerComponent, DatePickerDayViewComponent, NgrxDateInputDirective } from "./components/date";
import { DeletionConfirmationComponent } from "./components/deletion-confirmation/deletion-confirmation.component";
import { DescriptionIconComponent } from "./components/description-icon/description-icon.component";
import { FieldEditorDropdownComponent } from "./components/field-editor-dropdown/field-editor-dropdown.component";
import { FieldEditorModalComponent } from "./components/field-editor-modal/field-editor-modal.component";
import { GridSearchAutocompleteComponent } from "./components/grid-search-autocomplete/grid-search-autocomplete.component";
import { InfiniteScrollComponent } from "./components/infinite-scroll/infinite-scroll.component";
import { LengthCounterComponent } from "./components/length-counter/length-counter.component";
import { OpsLoadAnimationModule } from "./components/load-animation";
import { MultiselectTableRowDirective } from "./components/multiselect-table/multiselect-table-row.directive";
import { NoDataPanelComponent } from "./components/no-data-panel/no-data-panel.component";
import { NoDataComponent } from "./components/no-data/no-data.component";
import { PlaceholderComponent } from "./components/placeholder/placeholder.component";
import { PopupComponent } from "./components/popup/popup.component";
import { QuantityInputComponent } from "./components/quantity-unit/quantity-input.component";
import { ReferenceDataDropdownComponent } from "./components/reference-data-dropdown/reference-data-dropdown.component";
import { TextareaEditorComponent } from "./components/textarea-editor/textarea-editor.component";
import { TimePickerComponent } from "./components/time/time-picker.component";
import { VerifiedTimestampComponent } from "./components/timestamp/verified-timestamp.component";
import { FieldValidationComponent } from "./components/validation/field-validation.component";
import { FormlessValidationComponent } from "./components/validation/formless-validation.component";
import { SimpleFieldValidationComponent } from "./components/validation/simple-field-validation.component";
import { ValidationComponent } from "./components/validation/validation.component";
import { VoyageUpdatePreviewComponent } from "./components/voyage-update-preview/voyage-update-preview.component";
import { WarningComponent } from "./components/warning/warning.component";
import { WorksheetSaveButtonComponent } from "./components/worksheet-save-button/worksheet-save-button.component";
import { ClickOutsideDirective } from "./directives/click-outside/click-outside.directive";
import { FeatureDirective } from "./directives/feature.directive";
import { FocusDirective } from "./directives/focus/focus.directive";
import { FormToggleDirective } from "./directives/form-toggle-button.directive";
import { LabelFormControlNameDirective } from "./directives/label/label-form-control-name.directive";
import { NgrxAutoSizeInputDirective } from "./directives/ngrx-auto-size-input.directive";
import { NgrxNgSelectFocusDirective } from "./directives/ngrx-ng-select-focus/ngrx-ng-select-focus.directive";
import { NgrxScrollIntoViewDirective } from "./directives/ngrx-scroll-into-view.directive";
import { NgrxVisibilityDirective } from "./directives/ngrx-visibility.directive";
import { NumberInputDirective } from "./directives/number-input/number-input.directive";
import { RoleDirective } from "./directives/role/role.directive";
import { SelectWordsDirective } from "./directives/select-words/select-words.directive";
import { ValidationPopoverDirective } from "./directives/validation/validation-popover.directive";
import { EmailPreviewComponent } from "./email";
import { OpsFormGridModule } from "./form-grid";
import { NotificationComponent } from "./notification/notification.component";
import {
    CurrencySymbolPipe,
    DurationFormatPipe,
    FileSizePipe,
    MaritimeDatePipe,
    NumberFormatPipe,
    NumberFormatStyledDecimalPipe,
    TimezoneDatePipe,
    TimezoneDateRangePipe,
    TruncateCommaPipe,
    TruncatePipe
} from "./pipes/";
import { Positioning } from "./utils/positioning";
import { LaytimeEventFactsComponentValidator } from "./validators/component-validators";
import { LegacyWorksheetTitleComponent } from "../left-bar/worksheets/legacy-worksheet-title.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NgSelectModule,
        NgOptionHighlightModule,
        ClipboardModule,
        SelectButtonModule,
        InputSwitchModule,
        ConfirmDialogModule,
        OpsButtonModule,
        OpsFormGridModule,
        OpsLoadAnimationModule,
        TableModule
    ],
    declarations: [
        GridSearchAutocompleteComponent,
        WorksheetSaveButtonComponent,
        NumberInputDirective,
        LabelFormControlNameDirective,
        DatePickerComponent,
        DatePickerDayViewComponent,
        TimePickerComponent,
        ValidationComponent,
        FieldValidationComponent,
        SimpleFieldValidationComponent,
        FormlessValidationComponent,
        WarningComponent,
        FocusDirective,
        NoDataPanelComponent,
        NotificationComponent,
        BusyBoxComponent,
        TimezoneDatePipe,
        TimezoneDateRangePipe,
        UserAutosuggestComponent,
        ActionStatusPipe,
        VesselAutosuggestComponent,
        VoyageStatusPipe,
        TruncatePipe,
        TruncateCommaPipe,
        FileSizePipe,
        FixtureAutosuggestComponent,
        NumberFormatPipe,
        NumberFormatStyledDecimalPipe,
        PlaceholderComponent,
        SelectWordsDirective,
        ValidationPopoverDirective,
        ClickOutsideDirective,
        MaritimeDatePipe,
        EmailPreviewComponent,
        PopupComponent,
        VerifiedTimestampComponent,
        DeletionConfirmationComponent,
        ReferenceDataDropdownComponent,
        FormToggleDirective,
        CurrencySymbolPipe,
        RoleDirective,
        DurationFormatPipe,
        InfiniteScrollComponent,
        VoyageUpdatePreviewComponent,
        QuantityInputComponent,
        DateInputComponent,
        NgrxAutoSizeInputDirective,
        NgrxDateInputDirective,
        NgrxNgSelectFocusDirective,
        NgrxScrollIntoViewDirective,
        NgrxVisibilityDirective,
        MultiselectTableRowDirective,
        LengthCounterComponent,
        FeatureDirective,
        FieldEditorDropdownComponent,
        FieldEditorModalComponent,
        FeatureDirective,
        DescriptionIconComponent,
        NoDataComponent,
        TextareaEditorComponent,
        LegacyWorksheetTitleComponent
    ],
    providers: [Positioning, DatePipe, { provide: "ComponentValidator", useClass: LaytimeEventFactsComponentValidator, multi: true }],
    exports: [
        ClipboardModule,
        CommonModule,
        FormsModule,
        NgbModule,
        InputSwitchModule,
        ConfirmDialogModule,
        NgSelectModule,
        NgOptionHighlightModule,
        OpsButtonModule,
        OpsFormGridModule,
        OpsLoadAnimationModule,
        GridSearchAutocompleteComponent,
        WorksheetSaveButtonComponent,
        NumberInputDirective,
        LabelFormControlNameDirective,
        DatePickerComponent,
        TimePickerComponent,
        ValidationComponent,
        FieldValidationComponent,
        SimpleFieldValidationComponent,
        FormlessValidationComponent,
        WarningComponent,
        FocusDirective,
        NoDataPanelComponent,
        NotificationComponent,
        BusyBoxComponent,
        TimezoneDatePipe,
        TimezoneDateRangePipe,
        UserAutosuggestComponent,
        ActionStatusPipe,
        VesselAutosuggestComponent,
        FixtureAutosuggestComponent,
        VoyageStatusPipe,
        TruncatePipe,
        TruncateCommaPipe,
        FileSizePipe,
        NumberFormatPipe,
        NumberFormatStyledDecimalPipe,
        PlaceholderComponent,
        ValidationPopoverDirective,
        ClickOutsideDirective,
        MaritimeDatePipe,
        VerifiedTimestampComponent,
        DeletionConfirmationComponent,
        ReferenceDataDropdownComponent,
        FormToggleDirective,
        CurrencySymbolPipe,
        RoleDirective,
        DurationFormatPipe,
        InfiniteScrollComponent,
        VoyageUpdatePreviewComponent,
        QuantityInputComponent,
        DateInputComponent,
        MultiselectTableRowDirective,
        NgrxAutoSizeInputDirective,
        NgrxDateInputDirective,
        NgrxNgSelectFocusDirective,
        NgrxScrollIntoViewDirective,
        NgrxVisibilityDirective,
        LengthCounterComponent,
        FeatureDirective,
        DescriptionIconComponent,
        NoDataComponent,
        PopupComponent,
        TextareaEditorComponent,
        LegacyWorksheetTitleComponent
    ]
})
export class SharedModule {}
