import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { openFixturePopupEffect$, openFixturePopupFailEffect$, openFixturePopupLockFailEffect$, openFixturePopupSuccessEffect$ } from "./open-fixture";
import { saveFixturePopupEffect$, saveFixturePopupFailEffect$, saveFixturePopupSuccessEffect$ } from "./save-fixture";
import { FixturePopupFeatureState } from "./state";
import { unlockFixturePopupEffect$, unlockFixturePopupFailEffect$ } from "./unlock-fixture";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { NotificationService } from "../../shared";

@Injectable()
export class FixturePopupEffects {
    openFixturePopupEffect$ = openFixturePopupEffect$(this.actions$, this.fixtureHttpService);

    openFixturePopupSuccessEffect$ = openFixturePopupSuccessEffect$(this.actions$, this.fixtureHttpService, this.fixtureDataService);

    saveFixturePopupEffect$ = saveFixturePopupEffect$(this.actions$, this.store, this.fixtureHttpService, this.fixtureDataService);

    unlockFixturePopupEffect$ = unlockFixturePopupEffect$(this.actions$, this.store, this.fixtureHttpService, this.fixtureDataService);

    openFixturePopupFailEffect$ = openFixturePopupFailEffect$(this.actions$, this.notificationService);

    openFixturePopupLockFailEffect$ = openFixturePopupLockFailEffect$(this.actions$, this.notificationService);

    saveFixturePopupSuccessEffect$ = saveFixturePopupSuccessEffect$(this.actions$, this.fixtureDataService);

    saveFixturePopupFailEffect$ = saveFixturePopupFailEffect$(this.actions$, this.notificationService);

    unlockFixturePopupFailEffect$ = unlockFixturePopupFailEffect$(this.actions$, this.notificationService);

    constructor(
        private actions$: Actions,
        private store: Store<FixturePopupFeatureState>,
        private fixtureHttpService: FixtureHttpService,
        private fixtureDataService: FixtureDataService,
        private notificationService: NotificationService
    ) {}
}
