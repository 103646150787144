<ops-table-insert-overlay excludeLastColumnsFromWidth="1" [disable]="readonly" (insert)="insert($event)">
    <p-table
        [tableStyleClass]="gridClasses"
        [value]="laytimeEvents"
        dataKey="laytimeEventId"
        [rowTrackBy]="trackBy"
        [formGridReadonly]="readonly">
        <ng-template pTemplate="header">
            <tr [class.no-laytime-events]="!hasLaytimeEvents">
                <th>Events</th>
                <th>
                    Date/Time
                    <button
                        *ngIf="!readonly && !laytimeEventsOrdered"
                        (click)="order.emit(); t.close()"
                        type="button"
                        class="btn-reorder has-icon icon--in-negotiation ml-auto rotate90"
                        ngbTooltip="Re-order laytime events by date/time"
                        #t="ngbTooltip"
                        data-test-order-laytime-events-button
                    ></button>
                </th>
                <th class="text-right">%</th>
                <th>
                    Start/Stop
                    <span class="pl-2" *ngIf="laytimeEventsOrdered && totalLaytime"> {{ totalLaytime | number:"0.2-2" }}</span>
                </th>
                <th *ngIf="isCargoVisible">Cargo</th>
                <th>Demurrage Reason</th>
                <th>Comments</th>
                <th *ngIf="!readonly"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr class="form-group"
                formGridRow
                [attr.rowkey]="rowData.laytimeEventId">
                <td
                    warningActive
                    [ngrxFormControlId]="rowData.form.controls.type.id"
                    ngrxFormControlIdMatch="equals"
                    pEditableColumn
                    [formGridCell]="rowData.form.controls.type"
                    data-test-laytime-event>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-refdata-dropdown
                                type="LaytimeEvent"
                                placeholder="Select Laytime"
                                [ngrxFormControlState]="rowData.form.controls.type"
                                [ngrxEnableFocusTracking]="true"
                                [opsValidationPopover]="typeValidation"
                                formGridInput
                                bindLabel="name"
                                data-test-laytime-event-type>
                            </ops-refdata-dropdown>
                            <ng-template #typeValidation>
                                <ops-field-validation
                                    *ngIf="rowData.form.controls.type.errors.required"
                                    fieldDisplayName="Event type"
                                    errorMessage="is required">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.type?.value?.name}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    warningActive
                    [ngrxFormControlId]="rowData.form.controls.eventDate.id"
                    [ngbTooltip]="getTimeConventionChangeTooltip(rowData.offsetDiffWithPrevEvent)"
                    ngrxFormControlIdMatch="equals"
                    pEditableColumn
                    [formGridCell]="rowData.form.controls.eventDate"
                    data-test-laytime-event-date>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-date-input
                                [timeZone]="locationTimezone"
                                [enableTime]="true"
                                tabbingBehaviour="time"
                                [ngrxFormControlState]="rowData.form.controls.eventDate"
                                [ngrxValueConverter]="dateValueConverter"
                                [defaultFocusDate]="(rowIndex > 0 ? laytimeEvents[rowIndex - 1].form.value.eventDate : null) || arrivalDate"
                                [opsValidationPopover]="eventDateValidation"
                                formGridInput
                                (focus)="onEventDateFocus(rowData.form.controls.eventDate.id, $event)"
                                (blur)="onEventDateBlur(rowData.form.controls.eventDate.id, $event)"
                                (closed)="onEventDateBlur(rowData.form.controls.eventDate.id, $event)"
                                data-test-event-date
                            ></ops-date-input>
                            <ng-template #eventDateValidation>
                                <ops-field-validation
                                    *ngIf="rowData.form.controls.eventDate.errors.required"
                                    fieldDisplayName="Event Date"
                                    errorMessage="is required for {{rowData.form.value.type?.value?.name}} on last discharge for closed fixtures">
                                </ops-field-validation>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.eventDate | maritimeDate:true:locationTimezone}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    pEditableColumn
                    [formGridCell]="rowData.form.controls.percentage"
                    [formGridCellReadonly]="rowIndex === 0"
                    data-test-laytime-event-percentage>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                type="text"
                                class="form-control text-right"
                                [opsNumber]="{ precision: 2, showTrailingZeros: false, minValue: 0, maxValue: 100 }"
                                [ngrxFormControlState]="rowData.form.controls.percentage"
                                [readonly]="rowIndex === 0"
                                formGridInput
                                data-test-event-percentage
                            />
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value text-right">{{
                                getOutputPercentage(rowData.form.value.percentage) | percent:"0.0-2"
                            }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    pEditableColumn
                    pFocusCellSelector="button"
                    [formGridCell]="rowData.form.controls.isStartOrStop"
                    [formGridCellReadonly]="!rowData.form.value.eventDate || readonly || !laytimeEventsOrdered"
                    class="ops-fg-action-cell"
                    data-test-laytime-event-stop-start
                    >
                    <div class="laytime-stopstart">
                        <button
                            *ngIf="rowData.form.value.eventDate"
                            type="button"
                            class="has-icon laytime-stopstart"
                            [ngClass]="getStartStopClass(rowData.intervalPart)"
                            [disabled]="readonly || !laytimeEventsOrdered"
                            opsFormToggle
                            [ngrxFormControlState]="rowData.form.controls.isStartOrStop"
                            formGridInput
                            data-test-event-start-stop
                            >
                        </button>
                        <span *ngIf="laytimeEventsOrdered && isStop(rowData.intervalPart)">{{ rowData.elapsedHours | number:"0.2-2" }} hrs</span>
                    </div>
                </td>
                <td *ngIf="rowData.form.controls.cargoId"
                    pEditableColumn
                    [formGridCell]="rowData.form.controls.cargoId"
                    warningActive
                    [ngrxFormControlId]="rowData.form.controls.cargoId.id"
                    ngrxFormControlIdMatch="equals"
                    data-test-laytime-cargo
                    >
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ng-select
                                class="ops-select form-control"
                                [items]="cargoes"
                                [ngrxFormControlState]="rowData.form.controls.cargoId"
                                placeholder="Select"
                                [clearable]="true"
                                bindLabel="name"
                                bindValue="cargoId"
                                formGridInput
                                data-test-cargo
                                [selectOnTab]="true">
                                <ng-template ng-option-tmp let-item="item">
                                    <span title="{{item.name}}">{{item.name}}</span>
                                </ng-template>
                            </ng-select>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{ rowData.cargoName }}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td
                    pEditableColumn
                    [formGridCell]="rowData.form.controls.demurrageReason"
                    warningActive
                    [ngrxFormControlId]="rowData.form.controls.demurrageReason.id"
                    ngrxFormControlIdMatch="equals"
                    data-test-demurrage-reason
                    >
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <ops-refdata-dropdown
                                type="DemurrageReason"
                                placeholder="Select"
                                [ngrxFormControlState]="rowData.form.controls.demurrageReason"
                                formGridInput
                                data-test-demurrage-reason
                            ></ops-refdata-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.demurrageReason?.value?.name}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td pEditableColumn [formGridCell]="rowData.form.controls.comments" data-test-comments>
                    <ops-cellEditor>
                        <ng-template pTemplate="input">
                            <input
                                type="text"
                                class="form-control"
                                [ngrxFormControlState]="rowData.form.controls.comments"
                                ngrxUpdateOn="blur"
                                formGridInput
                                data-test-comments />
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span class="ops-fg-cell-value">{{rowData.form.value.comments}}</span>
                        </ng-template>
                    </ops-cellEditor>
                </td>
                <td *ngIf="!readonly" pEditableColumn pFocusCellSelector="button" class="ops-fg-action-cell">
                    <ops-icon-button
                        type="delete"
                        (click)="remove.emit(rowData.laytimeEventId)"
                        (keyup.enter)="remove.emit(rowData.laytimeEventId)"
                        data-test-remove-laytime-event-button
                    ></ops-icon-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <a *ngIf="!readonly" class="ops-fg-add-row" (click)="add.emit()" (keydown.enter)="add.emit()" tabindex="0">Add Laytime Event</a>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr *ngIf="readonly" class="no-laytime-events">
                <td colspan="6" class="no-laytime-events-text">
                    <div class="add-laytime-event-button-container">
                        <div class="left">
                            <span class="has-icon icon--lightbulb-outline"></span>
                            <span>No events are currently added</span>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ops-table-insert-overlay>
