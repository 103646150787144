import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { DateTime, Duration } from "luxon";
import { FocusAction, NgrxValueConverters, UnfocusAction } from "ngrx-forms";

import { RowInsertPosition, TableInsertPosition } from "../../../shared/form-grid";
import { isNullOrUndefined, stringifyDuration, zeroDuration } from "../../../shared/utils";
import { CargoItem, IntervalPart, LaytimeEventId, LaytimeEventState } from "../../state";

@Component({
    selector: "ops-laytime-events",
    templateUrl: "./laytime-events.component.html",
    styleUrls: ["./laytime-events.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaytimeEventsComponent {
    readonly dateValueConverter = NgrxValueConverters.dateToISOString;

    @Input() laytimeEvents: ReadonlyArray<LaytimeEventState>;
    @Input() laytimeEventsOrdered: boolean;
    @Input() totalLaytime?: number;
    @Input() locationTimezone: string;
    @Input() arrivalDate: DateTime;
    @Input() readonly: boolean;
    @Input() cargoes: ReadonlyArray<CargoItem>;

    @Output() readonly add = new EventEmitter();
    @Output() readonly clone = new EventEmitter<LaytimeEventId>();
    @Output() readonly remove = new EventEmitter<LaytimeEventId>();
    @Output() readonly order = new EventEmitter();

    get hasLaytimeEvents(): boolean {
        return this.laytimeEvents && this.laytimeEvents.length > 0;
    }

    get isCargoVisible(): boolean {
        return this.hasLaytimeEvents && !!this.laytimeEvents[0].form.controls.cargoId;
    }

    get gridClasses(): string {
        if (this.isCargoVisible) {
            return this.readonly ? "ops-form-grid ops-fg-readonly laytime-events-with-cargo" : "ops-form-grid laytime-events-with-cargo";
        }

        return this.readonly ? "ops-form-grid ops-fg-readonly laytime-events-without-cargo" : "ops-form-grid laytime-events-without-cargo";
    }

    constructor(@Inject(ActionsSubject) private actionsSubject: ActionsSubject | null) {}

    getStartStopClass(intervalPart: IntervalPart) {
        const isStart = intervalPart === IntervalPart.Start;
        const isStop = intervalPart === IntervalPart.Stop;
        const isOutside = intervalPart === IntervalPart.Outside;
        const isImplicitStop = intervalPart === IntervalPart.ImplicitStop;

        return {
            "laytime-start": !isStop,
            "laytime-stop": isStop || isImplicitStop,
            "laytime-outside": isOutside,
            "icon--play-fill": isStart,
            "icon--stop-fill": isStop,
            "icon--play-outline": !isStart && !isStop,
            "icon--stop-outline": isImplicitStop
        };
    }

    isStop(intervalPart: IntervalPart) {
        return intervalPart === IntervalPart.Stop || intervalPart === IntervalPart.ImplicitStop;
    }

    onEventDateFocus(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new FocusAction(controlId));
        }
    }

    onEventDateBlur(controlId: string, elementName: string) {
        if (!elementName) {
            this.actionsSubject.next(new UnfocusAction(controlId));
        }
    }

    trackBy(laytimeEventState: LaytimeEventState) {
        return laytimeEventState.laytimeEventId;
    }

    insert(tableInsertPosition: TableInsertPosition) {
        if (!this.readonly) {
            const index = this.laytimeEvents.findIndex((e) => e.laytimeEventId === tableInsertPosition.id);
            return tableInsertPosition.position === RowInsertPosition.TOP ? this.add.emit(index) : this.add.emit(index + 1);
        }
    }

    isFirst(laytimeEventId: LaytimeEventId) {
        return this.laytimeEvents.length > 0 && laytimeEventId === this.laytimeEvents[0].laytimeEventId;
    }

    isLast(laytimeEventId: LaytimeEventId) {
        return this.laytimeEvents.length > 0 && laytimeEventId === this.laytimeEvents[this.laytimeEvents.length - 1].laytimeEventId;
    }

    getOutputPercentage(value: number) {
        return isNullOrUndefined(value) ? null : value / 100;
    }

    getTimeConventionChangeTooltip(offsetDiffWithPrevEvent: Duration) {
        if (offsetDiffWithPrevEvent && !offsetDiffWithPrevEvent.equals(zeroDuration())) {
            const isPositive = offsetDiffWithPrevEvent > zeroDuration();

            return `Clocks ${isPositive ? "go forward" : "went back"} ${stringifyDuration(offsetDiffWithPrevEvent)}`;
        }

        return null;
    }
}
