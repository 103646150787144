<div class="contents-container">
    <div class="tabs-container">
        <p-tabView>
            <p-tabPanel *ngFor="let tab of tabs" [header]="tab.title" [selected]="tab.active">
                <ng-template pTemplate="header">
                    <div class="tab-header">
                        <span *ngIf="!tab.form.valid && !tab.form.disabled" class="has-icon icon--warning"></span>
                        <span class="tab-title">{{ tab.title }}</span>
                    </div>
                </ng-template>
                <div class="tab-panel-container">
                    <div *ngIf="tab.title === 'Comments'">
                        <ops-fixture-popup-comments [form]="tab.form"></ops-fixture-popup-comments>
                    </div>
                    <div *ngIf="tab.title === 'Fixture Info'">
                        <ops-fixture-popup-fixture-info [form]="tab.form" [fixture]="tab.fixture"></ops-fixture-popup-fixture-info>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>