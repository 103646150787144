import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";

import { FixturePopupTab } from "./models/fixture-popup-tab";
import { AuthService } from "../../core";
import { saveFixturePopupAction, selectAnimatingSaveButton, FixturePopupData, FixturePopupFeatureState, unlockFixturePopupAction } from "../../state/fixture-popup";
import { Fixture } from "../shared/models";

@Component({
    selector: "ops-fixture-popup",
    templateUrl: "./fixture-popup.component.html",
    styleUrls: ["./fixture-popup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupComponent {
    header: string;
    tabs: FixturePopupTab[];
    isReadOnly: boolean;
    lockedBy: string | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FixturePopupData,
        private store: Store<FixturePopupFeatureState>,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService
    ) {
        this.header = data.header;
        this.isReadOnly = (data.fixture.lockedBy && data.fixture.lockedBy.userId !== this.authService.user.userId) ?? false;
        this.lockedBy = data.fixture.lockedBy?.fullName;
        this.tabs = [this.createCommentsTab(data.fixture, data.field), this.createFixtureInfoTab(data.fixture, data.field)];
        this.tabs.forEach((tab) => {
            if (this.isReadOnly) {
                tab.form.disable();
            }
        });
    }

    get animatingSaveButton$() {
        return this.store.select(selectAnimatingSaveButton);
    }

    onSave() {
        if (this.tabs.every((t) => t.form.valid)) {
            this.store.dispatch(saveFixturePopupAction());
        }
    }

    onCancel() {
        this.store.dispatch(unlockFixturePopupAction());
    }

    private createCommentsTab(fixture: Fixture, field?: string) {
        return {
            title: "Comments",
            fixture,
            form: this.formBuilder.group({
                comments: [fixture.comments]
            }),
            active: field === "comments"
        };
    }

    private createFixtureInfoTab(fixture: Fixture, field?: string) {
        return {
            title: "Fixture Info",
            fixture,
            form: this.formBuilder.group({
                operators: [fixture.operators],
                voyageReference: [fixture.voyageReference],
                liftingNumber: [fixture.liftingNumber]
            }),
            active: !!field && ["operator", "operatorCode", "liftingNumber", "voyageReference"].includes(field)
        };
    }
}
