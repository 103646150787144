import { Actions, createEffect, ofType } from "@ngrx/effects";
import { On, Store, createAction, on, props } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, tap, withLatestFrom } from "rxjs/operators";

import { selectFixturePopupData, selectUnlockOnClose } from "./selectors";
import { FixturePopupFeatureState, FixturePopupState, initialState } from "./state";
import { FixtureDataService } from "../../fixture/services/fixture-data.service";
import { FixtureHttpService } from "../../fixture/services/fixture-http.service";
import { deepCopy, GENERIC_ERROR_MESSAGE, NotificationService } from "../../shared";

/* ACTIONS */
const SAVE_FIXTURE_POPUP_NAME = "[Fixture Popup] Save";

export const saveFixturePopupAction = createAction(SAVE_FIXTURE_POPUP_NAME);
export const saveFixturePopupSuccessAction = createAction(`${SAVE_FIXTURE_POPUP_NAME} Success`);
export const saveFixturePopupFailAction = createAction(`${SAVE_FIXTURE_POPUP_NAME} Fail`, props<{ error: Error }>());

/* REDUCERS */
export const saveFixturePopupReducer: On<FixturePopupState> = on(saveFixturePopupAction, (state) => ({
    ...state,
    animatingSaveButton: true
}));

export const saveFixturePopupSuccessReducer: On<FixturePopupState> = on(saveFixturePopupSuccessAction, () => initialState);

export const saveFixturePopupFailReducer: On<FixturePopupState> = on(saveFixturePopupFailAction, (state) => ({
    ...state,
    animatingSaveButton: false
}));

/* EFFECTS */
export const saveFixturePopupEffect$ = (
    actions$: Actions,
    store: Store<FixturePopupFeatureState>,
    fixtureHttpService: FixtureHttpService,
    fixtureDataService: FixtureDataService
) =>
    createEffect(() =>
        actions$.pipe(
            ofType(saveFixturePopupAction),
            withLatestFrom(fixtureDataService.currentFixture$, store.select(selectFixturePopupData), store.select(selectUnlockOnClose)),
            filter(([, fixture]) => !!fixture),
            exhaustMap(([, fixture, data, unlockOnClose]) =>
                fixtureHttpService.save(deepCopy(fixture), unlockOnClose).pipe(
                    map(() => {
                        data?.onSave(fixture);
                        return saveFixturePopupSuccessAction();
                    }),
                    catchError((error) => of(saveFixturePopupFailAction({ error })))
                )
            )
        )
    );

export const saveFixturePopupSuccessEffect$ = (actions$: Actions, fixtureDataService: FixtureDataService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(saveFixturePopupSuccessAction),
                tap(() => fixtureDataService.clearFixture())
            ),
        { dispatch: false }
    );

export const saveFixturePopupFailEffect$ = (actions$: Actions, notificationService: NotificationService) =>
    createEffect(
        () =>
            actions$.pipe(
                ofType(saveFixturePopupFailAction),
                tap(() => notificationService.error("Failed to save fixture.", GENERIC_ERROR_MESSAGE))
            ),
        { dispatch: false }
    );
