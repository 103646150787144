import { Location } from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as R from "ramda";
import { combineLatest, fromEvent, Observable, of, Subject } from "rxjs";
import { filter, first, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";

import { AppInsightsService, AuthService, CanDeactivateComponent } from "@ops/core";
import { Enumeration } from "@ops/shared/reference-data";

import { LeftBarStateService } from "../left-bar/left-bar-state.service";
import { FavoriteDataService } from "../left-bar/shared/favorite-data.service";
import { createFavoriteFromFixture } from "../left-bar/shared/models/favorite.model";
import { BusyBoxService } from "../shared/components/busy-box/busy-box.service";
import { DeletionConfirmationComponent } from "../shared/components/deletion-confirmation/deletion-confirmation.component";
import { EmailPreviewService } from "../shared/email";
import { SyncCargoAllowedRatesCommand } from "./laytime-tab/cargo-allowed-rates/commands/sync-cargo-allowed-rates.command";
import { FixtureDataService } from "./services/fixture-data.service";
import { VoyageDataService } from "./services/voyage-data.service";
import { DataSourceCommand } from "./shared/datasource-command";
import { Division, Fixture, FixtureSource, FixtureType, Voyage } from "./shared/models";
import {
    FixtureDataInterop,
    FixtureFeatureState,
    saveFixture,
    selectCurrentFixtureBusy,
    selectCurrentFixtureLockedByCurrentUser,
    selectCurrentFixtureVoyagesAreDirty
} from "./state";
import { selectCurrentFixtureReadyForSave } from "./state/validation";
import { TimeCharterComponent } from "./time-charter/time-charter.component";

@Component({
    selector: "ops-fixture",
    templateUrl: "./fixture.component.html",
    styleUrls: ["./fixture.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixtureComponent implements OnInit, OnDestroy, CanDeactivateComponent {
    static componentName = "FixtureComponent";

    private readonly destroy$ = new Subject();

    private _fixtureId: string;
    private collapsed$: Observable<boolean>;
    private smallScreenSize = 968;
    private tabTitle: string;

    fixtureForm: UntypedFormGroup;
    fixtureTypes = FixtureType;

    favouritesLoading: boolean;

    hideDeleteConfirmation = true;
    isFavourite: boolean;
    fixtureSource: Enumeration;

    isLockedByCurrentUser = false;
    animatingSaveButton = false;
    animatingEditButton = false;
    animatingCancelButton = false;
    canCloneFixture = false;
    isAdmin = false;

    isFavourite$: Observable<boolean>;

    @ViewChildren(TimeCharterComponent) timeCharterComponent: QueryList<TimeCharterComponent>;
    @ViewChild("saveButton") saveButton: ElementRef<HTMLElement>;

    @Output() tcVoyageUpdated = new EventEmitter();

    get fixture$(): Observable<Fixture> {
        return this.fixtureDataService.currentFixture$;
    }

    get voyage$(): Observable<Voyage> {
        return this.voyageDataService.current$;
    }

    get voyages$(): Observable<Voyage[]> {
        return this.voyageDataService.voyages$;
    }

    get fixtureSave$(): Observable<Fixture> {
        return this.fixtureDataService.save$;
    }

    get voyageSave$(): Observable<Voyage> {
        return this.voyageDataService.save$;
    }

    get isKebabPanelVisible(): boolean {
        return !(this.animatingEditButton || this.isLockedByCurrentUser || !this.canCloneFixture) || this.isAdmin;
    }

    get isFavouriteHidden(): boolean {
        return this.animatingEditButton || this.isKebabPanelVisible;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private route: ActivatedRoute,
        private favouriteService: FavoriteDataService,
        private authService: AuthService,
        private fixtureDataService: FixtureDataService,
        private voyageDataService: VoyageDataService,
        private leftBarStateService: LeftBarStateService,
        private router: Router,
        private location: Location,
        private titleService: Title,
        private applicationInsights: AppInsightsService,
        @Inject("Window") private window: Window,
        private store: Store<FixtureFeatureState>,
        private busyBoxService: BusyBoxService,
        private fixtureDataInterop: FixtureDataInterop,
        private emailPreviewer: EmailPreviewService,
        private zone: NgZone
    ) {}

    @HostListener("window:beforeunload", ["$event"])
    confirmUnload($event: Event) {
        // This is only required for Chrome, handled correctly in other browsers
        if (this.emailPreviewer.locationChangePending) {
            return;
        }

        this.isDirty().subscribe((dirty) => {
            if (dirty) {
                $event.returnValue = true;
            }
        });
    }

    ngOnDestroy() {
        this.fixtureDataService.clearFixture();
        this.titleService.setTitle(this.tabTitle);

        this.applicationInsights.removeGlobalProperty("fixtureId");
        this.applicationInsights.removeGlobalProperty("voyageId");
        this.applicationInsights.removeGlobalProperty("division");
        this.applicationInsights.removeGlobalProperty("fixtureSource");
        this.applicationInsights.removeGlobalProperty("fixtureNumber");

        this.fixtureDataInterop.destroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.fixtureDataInterop.init();

        this.voyage$
            .pipe(
                withLatestFrom(this.fixture$),
                filter(([voyage, fixture]) => !!voyage && !!fixture),
                takeUntil(this.destroy$)
            )
            .subscribe(([{ cargoes, cargoAllowedRates }, { division }]) => {
                const cargoIds = cargoes?.map((x) => x.id) || [];
                const cargoIdsFromAllowedRates = cargoAllowedRates?.map((x) => x.cargoId) || [];

                if (!R.equals(cargoIds.sort(), cargoIdsFromAllowedRates.sort())) {
                    const command = new SyncCargoAllowedRatesCommand(cargoIds, division.id);
                    this.voyageDataService.handleUpdateCommand(command, true);
                }
            });

        this.createForm();

        // TODO: (NGRX) better animatingSaveButton etc implementation when fixtures migrated
        combineLatest([this.store.select(selectCurrentFixtureBusy), this.store.select(selectCurrentFixtureLockedByCurrentUser)])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([busy, lockedByCurrentUser]) => {
                this.isLockedByCurrentUser = lockedByCurrentUser;
                this.animatingSaveButton = busy;
                this.animatingEditButton = busy;
                this.animatingCancelButton = busy;
                this.busyBoxService.setApplicationBusy(busy);

                if (!busy && lockedByCurrentUser) {
                    this.fixtureForm.enable({ emitEvent: false, onlySelf: true });
                } else {
                    this.fixtureForm.disable({ emitEvent: false, onlySelf: true });
                }

                this.cd.markForCheck();
            });

        this.collapsed$ = this.leftBarStateService.isCollapsed$.pipe(take(1));
        this.tabTitle = this.titleService.getTitle();

        this.fixtureDataService.currentFixture$.pipe(takeUntil(this.destroy$)).subscribe(
            (fixture) => this.handleUpdatedFixture(fixture),
            (err) => {
                console.log("Handling reset fixture on error: ", err);
                this.resetFixture();
            }
        );

        this.fixtureDataService.currentFixture$
            .pipe(
                takeUntil(this.destroy$),
                filter((fixture) => {
                    const path = this.location.path();
                    return fixture && fixture.division.id === Division.tankers && path.indexOf("actions") === -1;
                }),
                take(1)
            )
            .subscribe(() => {
                this.router.navigate([{ outlets: { toolbar: "contacts" } }], {
                    replaceUrl: true,
                    queryParamsHandling: "merge"
                });
                if (this.window.innerWidth > this.smallScreenSize) {
                    this.leftBarStateService.open();
                }
            });

        this.fixtureDataService.save$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.fixtureForm.markAsPristine();
            this.fixtureForm.markAsUntouched();
        });

        this.favouriteService.favouritesLoading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
            this.favouritesLoading = loading;
            this.cd.markForCheck();
        });

        this.route.params
            .pipe(
                takeUntil(this.destroy$),
                filter((routeParams) => this._fixtureId !== routeParams["fixtureId"]),
                switchMap((routeParams) => {
                    const fixtureId = (this._fixtureId = routeParams["fixtureId"]);

                    return this.fixtureDataService.load(fixtureId);
                })
            )
            .subscribe();

        fromEvent(this.window, "popstate")
            .pipe(takeUntil(this.destroy$), withLatestFrom(this.collapsed$))
            .subscribe(([, collapsed]) => {
                if (collapsed) {
                    this.leftBarStateService.collapse();
                } else {
                    this.leftBarStateService.open();
                }
            });

        this.zone.runOutsideAngular(() =>
            fromEvent<KeyboardEvent>(this.window.document, "keydown")
                .pipe(takeUntil(this.destroy$))
                .subscribe((event) => this.handleKeyDown(event))
        );

        this.favouriteService.loadFavourites();

        this.isFavourite$ = this.favouriteService.favouritesQueried$.pipe(
            map((data) => {
                const favourite = data.map((f) => f.id).find((f) => f === this._fixtureId);
                this.isFavourite = !!favourite;
                return this.isFavourite;
            })
        );

        combineLatest([this.authService.hasRole("DuplicateFixtures"), this.fixtureDataService.currentFixture$])
            .pipe(
                takeUntil(this.destroy$),
                tap(([hasCloneRole, fixture]) => {
                    this.canCloneFixture =
                        fixture &&
                        fixture.fixtureSource &&
                        fixture.fixtureSource.id === FixtureSource.Ops &&
                        hasCloneRole &&
                        fixture.fixtureType &&
                        fixture.fixtureType.id === FixtureType.Voyage;
                })
            )
            .subscribe();

        this.authService
            .hasRole("Admin")
            .pipe(
                takeUntil(this.destroy$),
                tap((hasRole: boolean) => {
                    this.isAdmin = hasRole;
                })
            )
            .subscribe();
    }

    edit() {
        if (this.fixtureForm.enabled || !(this.animatingEditButton || !this.isLockedByCurrentUser)) {
            return;
        }

        this.animatingEditButton = true;
        this.busyBoxService.setApplicationBusy(true);
        this.fixtureDataService.lock();
    }

    save() {
        if (this.animatingSaveButton || this.fixtureForm.disabled) {
            return;
        }

        this.store
            .select(selectCurrentFixtureReadyForSave)
            .pipe(first())
            .subscribe((isReadyForSave) => {
                if (isReadyForSave) {
                    this.animatingSaveButton = true;
                    this.busyBoxService.setApplicationBusy(true);
                    this.cd.markForCheck();

                    this.store.dispatch(saveFixture());
                }
            });
    }

    cancel() {
        this.animatingCancelButton = true;
        this.busyBoxService.setApplicationBusy(true);
        this.fixtureDataService.unlock().subscribe(() => {
            this.fixtureForm.markAsPristine();
            this.fixtureForm.markAsUntouched();
        });
    }

    handleUpdateCommand(command: DataSourceCommand) {
        console.log(`FixtureComponent: Handling command=%c'${command.command.constructor.name}' for dataSource=%c'${command.dataSource}'`, "color:red", "color:blue");
        if (command.dataSource === "fixture") {
            this.fixtureDataService.handleUpdateCommand(command.command);
        } else {
            this.voyageDataService.handleUpdateCommand(command.command, command.isDataValid);
        }
    }

    toggleFavouriteStatus() {
        if (this.isFavourite) {
            this.favouriteService.removeFavorite(this._fixtureId);
            this.isFavourite = false;
        } else {
            const fixture = this.fixtureDataService.currentFixtureSnapshot;
            this.favouriteService.addFavorite(createFavoriteFromFixture(fixture, this.authService.systemUser.systemUserId));
            this.isFavourite = true;
        }
    }

    /**
     * Handles keyboard shortcuts. Note we're not using @HostBinding here as that triggers
     * change detection unnecessarily for the component.
     */
    handleKeyDown(event: KeyboardEvent) {
        if (!event.ctrlKey) {
            return;
        }

        if (event.altKey) {
            switch (event.code) {
                case "KeyE":
                    this.zone.run(() => this.edit());
                    break;
                case "KeyS":
                    setTimeout(() => {
                        this.saveButton.nativeElement.focus();
                        this.zone.run(() =>
                            setTimeout(() => {
                                this.save();
                            }, 50)
                        );
                    }, 0);
                    break;
                default:
                    break;
            }
        } else if (event.shiftKey && event.code === "Backspace") {
            this.zone.run(() =>
                this.router.navigate([{ outlets: { primary: ["fixtures"] } }], {
                    replaceUrl: true,
                    queryParamsHandling: "merge"
                })
            );
        }
    }

    canDeactivate() {
        return this.isDirty().pipe(map((dirty) => (dirty ? "Discard unsaved changes to this fixture?" : true)));
    }

    cloneFixture() {
        if (this.fixtureForm.enabled || this.animatingEditButton) {
            return;
        }

        this.animatingEditButton = true;
        this.busyBoxService.setApplicationBusy(true);
        this.fixtureDataService
            .cloneFixture(this._fixtureId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (fixtureId) => {
                    this.router.navigate([{ outlets: { primary: ["fixture", fixtureId] } }], { queryParamsHandling: "merge" });
                },
                (error) => {
                    this.resetFixture();
                    of(error);
                }
            );
    }

    unlockFixture() {
        this.animatingCancelButton = true;
        this.fixtureDataService
            .unlock(true)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.fixtureForm.markAsPristine();
                this.fixtureForm.markAsUntouched();
            });
    }

    deleteFixture() {
        if (this.fixtureForm.enabled || this.animatingEditButton) {
            return;
        }

        this.animatingEditButton = true;
        this.busyBoxService.setApplicationBusy(true);
        this.fixtureDataService
            .deleteFixture(this._fixtureId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    const notificationTimeout = 5000;
                    setTimeout(() => {
                        this.busyBoxService.setApplicationBusy(false);
                        this.router.navigate([{ outlets: { primary: ["fixtures"] } }], {
                            replaceUrl: true,
                            queryParamsHandling: "merge"
                        });
                    }, notificationTimeout);
                },
                (error) => {
                    this.busyBoxService.setApplicationBusy(false);
                    of(error);
                }
            );
    }

    delete(deletionComponent: DeletionConfirmationComponent) {
        this.hideDeleteConfirmation = false;
        //setTimeout is required to activate animation from deletion component
        setTimeout(() => {
            deletionComponent.toggleConfirmation();
        }, 0);
    }

    setDeleteConfirmationStatus(status: boolean) {
        const animationTimeout = 500;
        setTimeout(() => {
            this.hideDeleteConfirmation = !status;
        }, animationTimeout);
    }

    private handleUpdatedFixture(fixture: Fixture) {
        console.group("FixtureComponent: HandleUpdatedFixture...");

        if (fixture) {
            console.log(` ++ Fixture: ${fixture.fixtureNumber}, loading should be hidden`);

            this.fixtureForm.patchValue({ fixtureId: fixture.fixtureId }, { emitEvent: false });
            this.titleService.setTitle((fixture.vessel && fixture.vessel.name) || "TBN");

            this.fixtureSource = fixture.fixtureSource;
        } else {
            console.log(" ++ No fixture, should be showing loading");
        }

        console.groupEnd();
    }

    private resetFixture() {
        if (this.isLockedByCurrentUser) {
            this.fixtureForm.enable({ emitEvent: false, onlySelf: true });
        } else {
            this.fixtureForm.disable({ emitEvent: false, onlySelf: true });
        }

        this.animatingEditButton = false;
        this.animatingCancelButton = false;
        this.animatingSaveButton = false;

        this.busyBoxService.setApplicationBusy(false);
        this.cd.markForCheck();
    }

    private createForm() {
        this.fixtureForm = new UntypedFormGroup({
            fixtureId: new UntypedFormControl(null)
        });
        this.fixtureForm.disable();
    }

    private isDirty() {
        return this.store.select(selectCurrentFixtureVoyagesAreDirty).pipe(
            take(1),
            map((voyagesAreDirty) => voyagesAreDirty || this.fixtureForm.dirty)
        );
    }
}
