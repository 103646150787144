import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NEVER, Observable } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";

import { deepCopy, deepEqual } from "@ops/shared";

import { Command, Mediator } from "../mediator";
import { Voyage } from "../shared/models";
import { selectCurrentFixtureVoyagesLoading } from "../state/fixture";
import { FixtureFeatureState } from "../state/model";
import { addVoyage, selectCurrentFixtureCurrentVoyages, selectCurrentFixtureVoyageCreating, selectCurrentFixtureVoyagesPersisting, selectCurrentVoyage } from "../state/voyage";
import { interopVoyageDataServicePublishAction } from "../state/voyage/interop";
import { VoyageHttpService } from "./index";

/* INTEROP SERVICE FOR COMPAT WHILST MIGRATING - SHOULD NOT BE EXTENDED */

@Injectable({
    providedIn: "root"
})
export class VoyageDataService {
    private current: Voyage;
    private _workingVoyage: Voyage;

    readonly current$: Observable<Voyage>;

    constructor(private store: Store<FixtureFeatureState>, public voyageHttpService: VoyageHttpService, private mediator: Mediator) {
        this.current$ = this.store.select(selectCurrentVoyage).pipe(
            tap((voyage) => {
                this.current = voyage;
                this._workingVoyage = null;
            }),
            shareReplay(1)
        );
    }

    get workingVoyage(): Voyage {
        // This is a workaround for places which emit multiple commands.
        // We use the same voyage copy until the store emits a new voyage.
        if (this._workingVoyage) {
            return this._workingVoyage;
        }

        if (this.current) {
            return (this._workingVoyage = deepCopy(this.current));
        }
    }

    get currentFromServer$(): Observable<Voyage> {
        return this.store.select(selectCurrentVoyage);
    }

    get voyages$(): Observable<Voyage[]> {
        return this.store.select(selectCurrentFixtureCurrentVoyages);
    }

    get currentVoyageSnapshot(): Voyage {
        return this.current;
    }

    get save$(): Observable<Voyage> {
        return NEVER; // This was never invoked by VoyageDataService
    }

    get saving$(): Observable<boolean> {
        return this.store.select(selectCurrentFixtureVoyagesPersisting);
    }

    get creatingVoyage$(): Observable<boolean> {
        return this.store.select(selectCurrentFixtureVoyageCreating);
    }

    get loading$(): Observable<boolean> {
        return this.store.select(selectCurrentFixtureVoyagesLoading);
    }

    create(): Observable<string> {
        this.store.dispatch(addVoyage());
        return NEVER;
    }

    handleUpdateCommand(command: Command, isDataValid: boolean) {
        const workingVoyage = this.workingVoyage;
        workingVoyage.isValid = isDataValid;

        const response = this.mediator.send(workingVoyage, command) || Promise.resolve();

        response.then(() => {
            if (deepEqual(workingVoyage, this.current)) {
                return;
            }

            console.log(`%cPublishing VOYAGE '${workingVoyage.voyageId}' on resolution of command '${command.constructor.name}'`, "background:yellow;color:darkgreen");

            this.publishUpdateInterop(workingVoyage, command.constructor.name);
        });
    }

    publishUpdate(voyage: Voyage) {
        console.warn("Call to unsupported method VoyageDataService.publishUpdate");

        this.publishUpdateInterop(voyage, null);
    }

    private publishUpdateInterop(voyage: Voyage, command: string | null) {
        this.store.dispatch(interopVoyageDataServicePublishAction({ voyage, command }));
    }
}
