import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { FixturePopupTab } from "./models/fixture-popup-tab";

@Component({
    selector: "ops-fixture-popup-contents",
    templateUrl: "./fixture-popup-contents.component.html",
    styleUrls: ["./fixture-popup-contents.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupContentsComponent {
    @Input() tabs: ReadonlyArray<FixturePopupTab>;
}
