import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as deepEqual from "fast-deep-equal";
import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError, switchMap, tap, withLatestFrom } from "rxjs/operators";

import { AuthService, NotificationService } from "@ops/core";

// Note the ../../shared imports are required to prevent circular deps
import { FixtureHttpService } from "./fixture-http.service";
import { ContactService } from "../../left-bar/contacts";
import { FixtureStatusType, FixtureType } from "../../left-bar/shared/models";
import { deepFreeze } from "../../shared/deep-freeze";
import { deepCopy } from "../../shared/utils";
import { isNullOrUndefined } from "../../shared/utils";
import { Command } from "../mediator/commands/command";
import { Mediator } from "../mediator/mediator";
import { Demurrage, Fixture } from "../shared/models";
import { Division } from "../shared/models/enums/division";
import { FixtureSource } from "../shared/models/enums/fixture-source";
import { OfficeType } from "../shared/models/enums/office-type";

@Injectable({
    providedIn: "root"
})
export class FixtureDataService {
    private readonly _lock$: Subject<string>;
    private readonly _unlock$: Subject<string>;
    private readonly _save$: Subject<Fixture>;
    private readonly _load$: Subject<string>;
    private readonly _isFixtureLockedByCurrentUser$: BehaviorSubject<boolean>;
    private readonly _currentFixture$: BehaviorSubject<Fixture>;
    private readonly _currentServerFixture$: BehaviorSubject<Fixture>;
    private _workingFixture: Fixture;
    private _messageDisplayTime = 5000;

    get fixtureType(): FixtureType {
        if (this._workingFixture && this._workingFixture.fixtureType) {
            return this._workingFixture.fixtureType.id;
        }
    }

    get fixtureStatus(): FixtureStatusType {
        if (this._workingFixture && this._workingFixture.fixtureStatus) {
            return this._workingFixture.fixtureStatus.id;
        }
    }

    get fixtureSource(): FixtureSource {
        if (this._workingFixture && this._workingFixture.fixtureSource) {
            return this._workingFixture.fixtureSource.id;
        }
        return FixtureSource.Gain;
    }

    get division(): Division {
        if (this._workingFixture && this._workingFixture.division) {
            return this._workingFixture.division.id;
        }
    }

    get office(): OfficeType {
        if (this._workingFixture && this._workingFixture.office) {
            return this._workingFixture.office.id;
        }
    }

    get lock$(): Observable<string> {
        return this._lock$;
    }

    get unlock$(): Observable<string> {
        return this._unlock$;
    }

    get save$(): Observable<Fixture> {
        return this._save$;
    }

    get currentFixture$(): Observable<Fixture> {
        return this._currentFixture$;
    }

    get currentServerFixture$(): Observable<Fixture> {
        return this._currentServerFixture$;
    }

    get isLockedByCurrentUser$(): Observable<boolean> {
        return this._isFixtureLockedByCurrentUser$;
    }

    get currentFixtureSnapshot(): Fixture {
        return this._currentFixture$.getValue();
    }

    constructor(
        private authService: AuthService,
        private mediator: Mediator,
        private fixtureHttpService: FixtureHttpService,
        private contactService: ContactService,
        private notificationService: NotificationService
    ) {
        this._currentFixture$ = new BehaviorSubject<Fixture>(null);
        this._currentServerFixture$ = new BehaviorSubject<Fixture>(null);
        this._lock$ = new Subject<string>();
        this._save$ = new Subject<Fixture>();
        this._load$ = new Subject<string>();
        this._unlock$ = new Subject<string>();
        this._isFixtureLockedByCurrentUser$ = new BehaviorSubject(false);
    }

    handleUpdateCommand(command: Command) {
        const response = this.mediator.send(this._workingFixture, command) || Promise.resolve();

        response.then(() => {
            console.log(`%cPublishing FIXTURE '${this._workingFixture.fixtureId}' on resolution of command '${command.constructor.name}'`, "background:yellow;color:darkgreen");
            this.publishFixture(this._workingFixture);
        });
    }

    updateDemurrage(demurrage: Demurrage) {
        if (deepEqual(this._workingFixture.demurrage, demurrage)) {
            return;
        }

        this._workingFixture.demurrage = demurrage;

        this.publishFixture(this._workingFixture);
    }

    // Temp NGRX solution
    removeLocationFromDemurrageClaim(destinationId: number, berthId?: number) {
        if (this.fixtureType !== FixtureType.Voyage) {
            throw Error("FixtureDataService.removeLocationFromDemurrageClaim not valid for non-voyage fixtures");
        }

        const demurrage = this._workingFixture.demurrage;

        if (demurrage && demurrage.claims) {
            const hasBerthId = !isNullOrUndefined(berthId);
            const workingDemurrage: Demurrage = deepCopy(demurrage);
            let matchingClaims = workingDemurrage.claims.filter((claim) => claim.destinationId === destinationId);

            if (hasBerthId) {
                matchingClaims = matchingClaims.filter((claim) => claim.berthId === berthId);
            }

            matchingClaims.forEach((claim) => {
                if (!hasBerthId) {
                    claim.destinationId = claim.berthId = null;
                } else {
                    claim.berthId = null;
                }
            });

            this.updateDemurrage(workingDemurrage);
        }
    }

    lock() {
        const workingFixture = this._workingFixture;

        of(workingFixture)
            .pipe(
                switchMap((x) => this.fixtureHttpService.lock(x.fixtureId)),
                tap((fixtureId) => this._lock$.next(fixtureId)),
                switchMap((fixtureId) => this.fixtureHttpService.get(fixtureId)),
                tap((serverFixture) => {
                    this._load$.next(serverFixture.fixtureId);
                    this._currentServerFixture$.next(deepFreeze(deepCopy(serverFixture)));
                }),
                catchError((err) => this.handleEditFixtureError(err))
            )
            .subscribe((x) => {
                this.publishFixture(x);
            });
    }

    unlock(showSuccess = false) {
        const workingFixture = this._workingFixture;

        return of(workingFixture)
            .pipe(
                switchMap((x) => this.fixtureHttpService.unlock(x.fixtureId)),
                tap((fixtureId) => this._unlock$.next(fixtureId)),
                switchMap((fixtureId) => this.fixtureHttpService.get(fixtureId)),
                tap((serverFixture) => {
                    this._load$.next(serverFixture.fixtureId);
                    this._currentServerFixture$.next(deepFreeze(deepCopy(serverFixture)));
                }),
                tap((serverFixture) => {
                    if (showSuccess) {
                        this.notificationService.success(
                            "",
                            `${this._workingFixture.vessel?.name ?? "TBN"} - ${serverFixture.fixtureId} unlocked successfully.`,
                            this._messageDisplayTime
                        );
                    }
                }),
                catchError((err) => this.handleEditFixtureError(err))
            )
            .pipe(tap((x) => this.publishFixture(x)));
    }

    save(unlock: boolean) {
        return of(this._workingFixture)
            .pipe(
                withLatestFrom(this._currentServerFixture$),
                switchMap(([workingFixture, serverFixture]) => {
                    const resetDemurrageStatus = this.shouldResetDemurrageStatus(workingFixture, serverFixture);
                    return this.fixtureHttpService.save(workingFixture, unlock, resetDemurrageStatus);
                }),
                tap((x) => {
                    this._save$.next(x);
                    this._currentServerFixture$.next(deepFreeze(deepCopy(x)));
                }),
                switchMap((x) => this.fixtureHttpService.get(x.fixtureId)),
                tap((x) => {
                    this._load$.next(x.fixtureId);
                }),
                catchError((err) => this.handleEditFixtureError(err))
            )
            .pipe(tap((x) => this.publishFixture(x)));
    }

    clearFixture(): void {
        this._currentFixture$.next(null);
        this._currentServerFixture$.next(null);
    }

    load(fixtureId: string): Observable<any> {
        this._load$.next(fixtureId);

        return this.fixtureHttpService.get(fixtureId).pipe(
            tap((fixture) => {
                if (fixture.division.id === Division.tankers) {
                    const errorMessage = `Can't load the tankers fixture ${fixtureId}.`;
                    this.notificationService.error("", errorMessage);
                    throwError(errorMessage);
                }

                this._currentServerFixture$.next(deepFreeze(deepCopy(fixture)));

                if (fixture && fixture.fixtureSource) {
                    this.contactService.loadCompanies(fixture.fixtureId, fixture.fixtureSource.id);
                }

                this.publishFixture(fixture);
            })
        );
    }

    deleteFixture(fixtureId: string): Observable<any> {
        return this.fixtureHttpService.delete(fixtureId).pipe(
            tap(
                () => {
                    this.notificationService.success("", `${this._workingFixture.vessel?.name ?? "TBN"} - ${fixtureId} deleted successfully.`, this._messageDisplayTime);
                },
                (error) => {
                    this.notificationService.error("", `Failed to delete the fixture ${fixtureId}. Please try later.`);
                    throwError(error);
                }
            )
        );
    }

    cloneFixture(fixtureId: string): Observable<string> {
        return this.fixtureHttpService.clone(fixtureId).pipe(
            tap(
                () => {
                    this.notificationService.success("", `${this._workingFixture.vessel?.name ?? "TBN"} - ${fixtureId} duplicated successfully.`, this._messageDisplayTime);
                },
                (error) => {
                    this.notificationService.error("", `Failed to duplicate the fixture ${fixtureId}. Please try later.`);
                    throwError(error);
                }
            )
        );
    }

    handleEditFixtureError(err$: HttpErrorResponse): Observable<Fixture> | Observable<never> {
        const httpConflict = 409;
        if (err$.status === httpConflict) {
            this.fixtureHttpService.get(this._workingFixture.fixtureId).subscribe((fixture) => {
                const message = `This fixture is locked by ${fixture.lockedBy.fullName}. Please ensure that it is unlocked to start editing.`;
                this.notificationService.warn("Locked", message);
                this._load$.next(fixture.fixtureId);
                this.publishFixture(fixture);
            });
            return of();
        }

        return throwError(err$);
    }

    publishFixture(fixture: Fixture) {
        this._workingFixture = fixture;
        const immutableFixture = deepFreeze(deepCopy(fixture)) as Fixture;
        this._currentFixture$.next(immutableFixture);
        this._isFixtureLockedByCurrentUser$.next(this.getIsLockedByCurrentUser(immutableFixture));
    }

    private shouldResetDemurrageStatus(workingFixture: Fixture, serverFixture: Fixture): boolean {
        const workingFixtureDemurrage = workingFixture.demurrage && workingFixture.demurrage.status ? workingFixture.demurrage.status.id : null;
        const serverFixtureDemurrage = serverFixture.demurrage && serverFixture.demurrage.status ? serverFixture.demurrage.status.id : null;

        return workingFixtureDemurrage === serverFixtureDemurrage;
    }

    private getIsLockedByCurrentUser(fixture: Fixture): boolean {
        return !!(fixture.lockedBy && fixture.lockedBy.userId === this.authService.systemUser.systemUserId);
    }
}
