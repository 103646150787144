import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";
import { FormControlState } from "ngrx-forms";
import { Observable } from "rxjs";

import { Division, Enumeration, ReferenceDataService } from "@ops/shared/reference-data";
import { getIsRequired } from "@ops/state";

import { FixtureDataService } from "../../../fixture/services/fixture-data.service";
import { FixtureType } from "../../../fixture/shared/models/enums/fixture-type";
import { ReferenceDataType } from "../../reference-data/reference-data-type";
import { isNullOrUndefined } from "../../utils";

@Component({
    selector: "ops-refdata-dropdown",
    templateUrl: "./reference-data-dropdown.component.html",
    styleUrls: ["./reference-data-dropdown.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReferenceDataDropdownComponent),
            multi: true
        }
    ]
})
export class ReferenceDataDropdownComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    referenceData$: Observable<Enumeration[]>;
    disabled = false;
    selectedValue = {};

    @Input() type: ReferenceDataType;
    @Input() readonly: boolean;
    @Input() placeholder: string;
    @Input() allowClear: boolean;
    @Input() ngrxFormControlState: FormControlState<ReferenceDataType>;
    @Input() sector: string;
    @Input() bindValue: string;
    @Input() isQuantityInputDropdown: boolean;
    @Input() options: ReadonlyArray<string>;
    @Input() appendTo: string;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly focus = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly blur = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly change = new EventEmitter();

    @ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

    get isOpen() {
        return this.ngSelect?.isOpen;
    }

    get showClearButton() {
        // Display the clear button if allowClear is explicitly true or the ngrx form control is not marked as required
        return (
            !this.disabled &&
            !this.readonly &&
            (!!this.allowClear || (isNullOrUndefined(this.allowClear) && !isNullOrUndefined(this.ngrxFormControlState) && !getIsRequired(this.ngrxFormControlState)))
        );
    }

    constructor(private el: ElementRef, private referenceDataService: ReferenceDataService, private fixtureDataService: FixtureDataService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type && (!changes.type.currentValue || changes.type.currentValue === changes.type.previousValue)) {
            this.referenceData$ = undefined;
        }
    }

    ngOnInit() {
        // Required for NGRX forms. This doesn't fix blur though, if we implement the blur handler
        // below, the ngrxFormControlState directive blurs the element which also causes the focused
        // child ng-select to blur.
        this.el.nativeElement.focus = () => setTimeout(() => this.ngSelect.focus());
        // this.el.nativeElement.blur = () => setTimeout(() => this.refDataDropdown.blur());
        this.setDefaultBindValue();
    }

    ngAfterViewInit() {
        this.ngSelect.focusEvent.subscribe(() => {
            this.focus.emit();
            this.loadReferenceData();
        });
        this.ngSelect.blurEvent.subscribe(() => this.blur.emit());
    }

    loadReferenceData() {
        const division = this.sector ? this.getDivisionFromSector() : this.fixtureDataService.division;
        const fixtureType = this.sector ? FixtureType.Voyage : this.fixtureDataService.fixtureType;

        this.setDefaultBindValue();

        if (this.type) {
            this.referenceData$ = this.referenceDataService.getReferenceDataByType(this.type, fixtureType, division);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    writeValue(value: any) {
        const boundValue = value && value[this.bindValue];
        if (value) {
            this.selectedValue = value;
        }
        this.ngSelect.writeValue(boundValue || value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.ngSelect.registerOnChange((id: number | string | null) => {
            const enumeration = (this.ngSelect.items as Enumeration[]).find((s) => s.id === id);
            fn(enumeration || id);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.ngSelect.registerOnTouched(fn);
    }

    setDisabledState?(isDisabled: boolean) {
        this.ngSelect.setDisabledState(isDisabled);
    }

    compare(e1: Enumeration, e2: Enumeration): boolean {
        return e1 && e2 ? e1.id === e2.id : e1 === e2;
    }

    private setDefaultBindValue() {
        if (!this.bindValue) {
            // bind the dropdown to the id property unless specified
            this.bindValue = "id";
        }
    }

    private getDivisionFromSector() {
        return (Object.values(Division) as Division[]).find((d) => d.name === this.sector)?.id;
    }
}
