import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { UpdateFixturePopupCommentsCommand } from "./commands/update-fixture-popup-comments-command";
import { FixturePopupCommentsFormModel } from "./models/fixture-popup-comments-form.model";
import { FixtureDataService } from "../services/fixture-data.service";
import { FormComponentBase } from "../shared/form-component-base";

@Component({
    selector: "ops-fixture-popup-comments",
    templateUrl: "./fixture-popup-comments.component.html",
    styleUrls: ["./fixture-popup-comments.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FixturePopupCommentsComponent extends FormComponentBase implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup;

    constructor(private fixtureDataService: FixtureDataService) {
        super();
    }

    ngOnInit(): void {
        this.subscribeToFormValueChanges(this.form, (model: FixturePopupCommentsFormModel) => {
            this.fixtureDataService.handleUpdateCommand(new UpdateFixturePopupCommentsCommand(model));
        });
    }

    ngOnDestroy(): void {
        this.removeFormSubscriptions();
    }
}
